import React from "react";

function ProjectTitle({ projectName, designer, yearOfCompletion }) {

  const underbarClass = designer?.website ? "link-underbar" : "";
  // console.log('a', designer.website)
//  if(designer?.website.includes("http")){
  // console.log('indlue https or http')
//  }
  return (
    <div className="project-title">
      <h2>{projectName?.toUpperCase()}</h2>
      <div className="project-title-line-wrapper">
        {/* <img alt="e" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1280px-Image_created_with_a_mobile_phone.png"/> */}
        <h2>
          <a
            key={`designer`}
            className={`${underbarClass}`}
            href={designer.website &&(designer?.website.includes("https")||designer?.website.includes("http")? designer.website: `https://${designer.website}`)}
          >
            {designer.name}
          </a>
         
        </h2>
        <h2>({yearOfCompletion})</h2>
      </div>
    </div>
  );
}

export default ProjectTitle;
