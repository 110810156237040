import React, { useState, useEffect } from "react";
import Geocoding from "../components/Geocoding";
import { BsPlusCircle, BsDashCircle } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Compressor from "compressorjs";
import {
  fetchInnovations,
  handleInnovationSelect,
} from "../components/Form/InnovationUtils";
import {
  fetchTypologies,
  handleTypologySelect,
} from "../components/Form/TypologyUtils";
// import ImageBlobReduce from "image-blob-reduce";
import { handleSubmit } from "../components/Form/SubmitUtils";
import PrimaryImgSection from "../components/Form/sections/PrimaryImgSection";
import OtherImgSection from "../components/Form/sections/OtherImgSection";
import DescriptionReferenceSection from "../components/Form/sections/DescriptionReferenceSection";
import InnovationReferenceSection from "../components/Form/sections/InnovationReferenceSection";
export function ProjectForm({ signOut, user }) {
  async function resizeImage(file, targetsize) {
    const targetSize = targetsize * 1024; // 400KB
    let Quality = targetSize / file.size;
    console.log(file);
    return new Promise((resolve) => {
      new Compressor(file, {
        maxWidth: 1200,
        quality: 1.2,
        success: (result) => {
          let resizedImage = new Blob([result], { type: result.type });
          let resizedSize = resizedImage.size;
          // If the resized image is still larger than the target size, iteratively reduce the quality
          while (resizedSize > targetSize && Quality > 0) {
            Quality -= 0.1; // reduce quality by 0.1
            console.log("quality", Quality);

            resizedImage = new Blob([result], { type: result.type });
            resizedSize = resizedImage.size;
          }

          resolve(resizedImage);
        },
      });
    });
  }
  const [projectName, setProjectName] = useState("");
  const [completionYear, setCompletionYear] = useState("");
  const [primaryConsultant, setPrimaryConsultant] = useState({
    name: "",
    website: "",
  });
  const [otherContributors, setOtherContributors] = useState([
    // { name: "", website: "" }, //this will generate an empty name and website in db
  ]);
  const [owner, setOwner] = useState({
    name: "",
    website: "",
  });
  const [innovationData, setInnovationData] = useState({
    innovationType: {
      primaryInnovation: {
        Id: null,
        innovationDescription: "",
      },
      secondaryInnovation: {
        Id: null,
        innovationDescription: "",
      },
      tertiaryInnovation: {
        Id: null,
        innovationDescription: "",
      },
    },
  });
  const [innovations, setInnovations] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const [selectedTypologies, setSelectedTypologies] = useState([]);
  const [projectDescription, setProjectDescription] = useState("");
  const [projectReference, setProjectReference] = useState();
  const [relatedResources, setRelatedResources] = useState([
    // { title: "", link: "" }, //this will generate an empty name and website in db
  ]);
  const [submittedBy, setSubmittedBy] = useState({
    firstName: "",
    lastName: "",
    emailAddress: user.attributes.email,
    organization: "",
  });
  const [primaryImage, setPrimaryImage] = useState();
  const [images, setImages] = useState([]);
  const [primaryImageByUrl, setPrimaryImageByUrl] = useState();
  const [imagesByUrl, setImagesByUrl] = useState([]);
  const [otherImgFilesCaptions, setOtherImgFilesCaptions] = useState([]);
  const [primaryImgFileCaption, setPrimaryImgFileCaption] = useState();

  const [location, setLocation] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleProjectSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // Check if required fields are filled out
    if (
      !location ||
      !location?.coordinates ||
      location?.coordinates.length === 0
    ) {
      alert("Please fill out valid address");
      return setIsLoading(false);
    }
    if (!primaryImage && !primaryImageByUrl?.url) {
      alert("Please fill out a primary image field!");
      return setIsLoading(false);
    }
    if (
      primaryImageByUrl?.url &&
      !primaryImageByUrl.imageSource &&
      !primaryImageByUrl.photoCredit
    ) {
      alert("Please fill out a caption of your primary image!");
      return setIsLoading(false);
    }

    const isImageFilled = imagesByUrl.some((img) => {
      if (!img.url) {
        alert("Please fill out all image urls or delete all empty urls!");
        return false;
      }
      if (!img.imageSource && !img.photoCredit) {
        alert("Please fill out all captions of your images!");
        return false;
      }
      return true;
    });
    console.log("isImageFilled", imagesByUrl, isImageFilled);
    if (imagesByUrl.length !== 0 && !isImageFilled) return setIsLoading(false);
    if (
      projectName &&
      completionYear &&
      primaryConsultant.name &&
      owner.name &&
      location &&
      projectDescription &&
      selectedTypologies.length !== 0 &&
      submittedBy.firstName &&
      submittedBy.lastName &&
      submittedBy.organization &&
      submittedBy.emailAddress &&
      innovationData.innovationType.primaryInnovation.Id &&
      innovationData.innovationType.primaryInnovation.innovationDescription
    ) {
      // Calculate the size of the payload excluding the images
      const payloadSize = new Blob([
        JSON.stringify({
          projectName,
          completionYear,
          primaryConsultant,
          otherContributors,
          owner,
          location,
          projectDescription,
          projectReference,
          innovationType: innovationData.innovationType,
          typologies: selectedTypologies,
          relatedResources,
          otherImgFilesCaptions,
          primaryImgFileCaption,
          submittedBy,
        }),
      ]).size;

      // Calculate the total size of the images without resizing
      let totalImageSize = primaryImage?.size || 0;
      for (const image of images) {
        totalImageSize += image.size;
      }
      // console.log("before", totalImageSize);
      let resizedPrimaryImage = primaryImage;
      // let resizedImages = images;
      let resizedImages = images.map((i) => new Blob([i]));

      // console.log(
      //   "resized innitialized val",
      //   resizedPrimaryImage,
      //   resizedImages
      // );
      // Check if the combined size exceeds the limit
      const maxSize = 4.6 * 1000 * 1000; // 5.8 MB
      console.log("size", payloadSize + totalImageSize, maxSize);
      if (payloadSize + totalImageSize > maxSize) {
        // Resize primary image
        console.log("insizde of primary resizing", primaryImage);
        if (primaryImage) {
          resizedPrimaryImage = await resizeImage(primaryImage, 400);
        }
        setPrimaryImage(resizedPrimaryImage);
        console.log("resizedPrimary", resizedPrimaryImage);
        // Resize additional images
        let resizedTotalImageSize = resizedPrimaryImage?.size || 0;

        resizedImages = [];

        await Promise.all(
          images.map(async (imageFile) => {
            console.log("d", imageFile);
            const resized = await resizeImage(imageFile, 400);
            return resizedImages.push(resized);
          })
        );
        setImages(resizedImages);
        console.log("resized others", resizedImages);
        // console.log("after", resizedTotalImageSize);
        // Check if the new combined size still exceeds the limit
        console.log("size", resizedTotalImageSize, payloadSize);
        if (payloadSize + resizedTotalImageSize > maxSize) {
          alert(
            "The total size of the payload, including images, exceeds the allowed limit. Please reduce the size or the number of images."
          );
          return setIsLoading(false);
        }
      }

      const formData = {
        projectName,
        completionYear,
        primaryConsultant,
        otherContributors,
        owner,
        location,
        projectDescription,
        projectReference,
        innovationType: innovationData.innovationType,
        typologies: selectedTypologies,
        relatedResources,
        primaryImageByUrl,
        imagesByUrl,
        otherImgFilesCaptions,
        primaryImgFileCaption,
        // primaryImage: resizedPrimaryImage,
        // images: resizedImages,
        submittedBy,
      };
      console.log("form", formData, resizedPrimaryImage, resizedImages);

      await handleSubmit(
        event,
        formData,
        resizedPrimaryImage,
        resizedImages,
        (response) => {
          console.log("Project created successfully:", response);
          setIsFormSubmitted(true);
        },
        (error) => {
          console.error("Error creating new project:", error);
          alert("Error creating new project. Please try again!");
          setIsLoading(false);
        }
      );
      setIsLoading(false);
    } else {
      alert("Please fill out all required fields!");
      setIsLoading(false);
    }
  };
  useEffect(
    () =>
      console.log("primary", primaryImage, "primary url", primaryImageByUrl),
    [primaryImage, primaryImageByUrl]
  );

  useEffect(() => {
    const fetchInnovationsData = async () => {
      const fetchedInnovations = await fetchInnovations();
      setInnovations(fetchedInnovations);
    };

    fetchInnovationsData();
  }, []);

  const handleInnovationChange = (event, innovationTypeKey) => {
    handleInnovationSelect(
      event,
      innovationTypeKey,
      innovations,
      setInnovationData
    );
  };

  useEffect(() => {
    const loadTypologies = async () => {
      const typologiesData = await fetchTypologies();
      setTypologies(typologiesData);
    };
    loadTypologies();
  }, []);

  const handleProjectDescriptionChange = (event) => {
    const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 250) {
      setProjectDescription(inputText);
    }
  };
  const handleOtherContributorNameChange = (index, event) => {
    const newOtherContributors = [...otherContributors];
    newOtherContributors[index].name = event.target.value;
    setOtherContributors(newOtherContributors);
  };

  const handleOtherContributorWebsiteChange = (index, event) => {
    const newOtherContributors = [...otherContributors];
    newOtherContributors[index].website = event.target.value;
    setOtherContributors(newOtherContributors);
  };

  const addOtherContributors = () => {
    setOtherContributors([...otherContributors, { name: "", website: "" }]);
  };

  const removeOtherContributors = (index) => {
    const newOtherContributors = [...otherContributors];
    newOtherContributors.splice(index, 1);
    setOtherContributors(newOtherContributors);
  };

  const handleAddRelatedResource = () => {
    setRelatedResources([...relatedResources, { title: "", link: "" }]);
  };

  const handleRemoveRelatedResource = (index) => {
    const newRelatedResources = [...relatedResources];
    newRelatedResources.splice(index, 1);
    setRelatedResources(newRelatedResources);
  };

  const handleRelatedResourceTitleChange = (event, index) => {
    const newRelatedResources = [...relatedResources];
    newRelatedResources[index].title = event.target.value;
    setRelatedResources(newRelatedResources);
  };

  const handleRelatedResourceLinkChange = (event, index) => {
    const newRelatedResources = [...relatedResources];
    newRelatedResources[index].link = event.target.value;
    setRelatedResources(newRelatedResources);
  };

  const handleSubmittedByChange = (event, field) => {
    setSubmittedBy((prevSubmittedBy) => ({
      ...prevSubmittedBy,
      [field]: event.target.value,
    }));
  };
  const handleInnovationWordsLimit = (e, innovationTypeKey) => {
    const words = e.target.value.split(/\s+/);
    if (words.length <= 250) {
      setInnovationData({
        ...innovationData,
        innovationType: {
          ...innovationData.innovationType,
          [innovationTypeKey]: {
            ...innovationData.innovationType[innovationTypeKey],
            innovationDescription: e.target.value,
          },
        },
      });
    }
  };
  // console.log("change innovation", innovationData);
  useEffect(() => {
    console.log("reference", projectReference, innovationData);
  }, [projectReference, innovationData]);
  return (
    <main className="container form-container">
      {isFormSubmitted ? (
        <div>
          <h3 className="after-submit-text">
            Thank you for completing the form!
          </h3>
          <button
            className="btn-submit btn-black btn-secondary"
            onClick={signOut}
          >
            Log out
          </button>
        </div>
      ) : (
        // onSubmit={handleProjectSubmit}
        <form className="form-wrapper">
          <section className="project-section">
            <div className="field-wrapper">
              <label>
                <h5>Project Name (required):</h5>
              </label>
              <input
                type="text"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                required
              />
            </div>
            <div className="consultant-wrapper">
              <h5> Primary Consultant:</h5>
              <div className="field-wrapper">
                <label>
                  <h6>Name (required):</h6>
                </label>
                <input
                  type="text"
                  value={primaryConsultant.name}
                  onChange={(e) =>
                    setPrimaryConsultant({
                      ...primaryConsultant,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="field-wrapper">
                <label>
                  <h6>
                    Website (Enter website URL including http:// or https://):
                  </h6>
                </label>
                <input
                  type="text"
                  value={primaryConsultant.website}
                  onChange={(e) =>
                    setPrimaryConsultant({
                      ...primaryConsultant,
                      website: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field-wrapper">
              <label>
                <h5> Completion Year (YYYY) (required):</h5>
              </label>
              <input
                type="text"
                pattern="[0-9]{4}"
                value={completionYear}
                onChange={(e) => setCompletionYear(e.target.value)}
                required
              />
            </div>
            <div className="field-wrapper">
              <label>
                <h5>Owner:</h5>
              </label>
              <div className="field-wrapper">
                <label>
                  <h6>Name (required):</h6>
                </label>
                <input
                  type="text"
                  value={owner.name}
                  onChange={(e) =>
                    setOwner({
                      ...owner,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="field-wrapper">
                <label>
                  <h6>
                    Website (Enter website URL including http:// or https://):
                  </h6>
                </label>
                <input
                  type="text"
                  value={owner.website}
                  onChange={(e) =>
                    setOwner({ ...owner, website: e.target.value })
                  }
                />
              </div>
              {/* <input
                type="text"
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
              /> */}
            </div>
            <div className="collaborators-wrapper">
              <h5>Other Contributors (Optional)</h5>

              {otherContributors.map((otherContributor, index) => (
                <div key={index} className="collaborator-wrapper">
                  <div className="field-wrapper">
                    <label>
                      <h6>Name:</h6>
                    </label>
                    <input
                      type="text"
                      value={otherContributor.name}
                      onChange={(e) =>
                        handleOtherContributorNameChange(index, e)
                      }
                    />
                  </div>
                  <div className="field-wrapper">
                    <label>
                      <h6>
                        Website (Enter website URL including http:// or
                        https://):
                      </h6>
                    </label>
                    <input
                      type="text"
                      value={otherContributors.website}
                      onChange={(e) =>
                        handleOtherContributorWebsiteChange(index, e)
                      }
                    />
                  </div>
                  {/* {index > 0 && ( */}
                  <button
                    type="button"
                    onClick={() => removeOtherContributors(index)}
                    className="btn-secondary btn-form"
                  >
                    <BsDashCircle />
                    <p className="p-detail">Remove Other Contributor</p>
                  </button>
                  {/* )} */}
                </div>
              ))}
              <button
                type="button"
                className="btn-secondary btn-form"
                onClick={addOtherContributors}
              >
                <BsPlusCircle />
                <p className="p-detail">Add Other Contributors</p>
              </button>
            </div>
            <div className="description-wrapper field-wrapper">
              <label>
                <h5>Project Description:</h5>
                <p className="p-detail">
                  Please give a brief description of the project. (max of 250
                  words) (required):
                </p>
              </label>
              <textarea
                value={projectDescription}
                onChange={handleProjectDescriptionChange}
                required
                // maxLength="250"
                placeholder="Required field. Description with max of 250 words"
              />
              <DescriptionReferenceSection
                projectReference={projectReference}
                setProjectReference={setProjectReference}
              />
            </div>
          </section>
          <div className="address-wrapper field-wrapper">
            <label>
              <h5>Address(required):</h5>
            </label>
            <Geocoding setLocation={setLocation} location={location} />
          </div>
          <section className="innovation-section">
            <h5>Innovation</h5>
            {[
              "primaryInnovation",
              "secondaryInnovation",
              "tertiaryInnovation",
            ].map((innovationTypeKey, index) => (
              <div key={innovationTypeKey} className="innovation-wrapper">
                <div className="innovation-filter-wrapper">
                  <label
                    htmlFor={`${innovationTypeKey}_innovationId`}
                    className="p-detail"
                  >
                    Select{" "}
                    {innovationTypeKey === "primaryInnovation"
                      ? "Primary"
                      : innovationTypeKey === "secondaryInnovation"
                      ? "Secondary"
                      : "Tertiary"}{" "}
                    Innovation {index === 0 && <span>(required)</span>}:
                  </label>
                  <select
                    id={`${innovationTypeKey}_innovationId`}
                    value={
                      innovationData.innovationType[innovationTypeKey].Id || ""
                    }
                    onChange={(e) =>
                      handleInnovationChange(e, innovationTypeKey)
                    }
                    className="form-filter"
                    required={index === 0 ? true : false}
                  >
                    <option value="">
                      Select a{" "}
                      {innovationTypeKey === "primaryInnovation"
                        ? "primary"
                        : innovationTypeKey === "secondaryInnovation"
                        ? "secondary"
                        : "tertiary"}{" "}
                      innovation
                    </option>
                    {innovations.map((innovation) => (
                      <option key={innovation._id} value={innovation._id}>
                        {innovation.innovationName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="innovation-description-wrapper">
                  <label
                    htmlFor={`${innovationTypeKey}_innovationDescription`}
                    className="p-detail"
                  >
                    Please Describe how the selected Innovation relates to the
                    structure in 250 words{" "}
                    {index === 0 && <span>(required)</span>}:
                  </label>
                  {/* <p>{`${250 - innovationData.innovationType[innovationTypeKey]
                        .innovationDescription.trim().split(/\s+/).length} words left`}</p> */}
                  <textarea
                    required={index === 0 ? true : false}
                    id={`${innovationTypeKey}_innovationDescription`}
                    value={
                      innovationData.innovationType[innovationTypeKey]
                        .innovationDescription
                    }
                    onChange={(e) => {
                      handleInnovationWordsLimit(e, innovationTypeKey);
                    }}
                    rows={5}
                    placeholder="Description with max of 250 words"
                  />
                  <InnovationReferenceSection
                    innovationType={innovationTypeKey}
                    innovationData={innovationData}
                    setInnovationData={setInnovationData}
                  />
                </div>
              </div>
            ))}
          </section>
          <section className="typology-section">
            <div className="typology-name-wrapper">
              <h5>Typology (required)</h5>
              <p className="p-detail">
                Select between 1 and 3 typologies that best relate to the
                structure:
              </p>
            </div>
            <div className="categories-wrapper">
              {typologies.map((typology) => (
                <div key={typology._id} className="category-wrapper">
                  <input
                    type="checkbox"
                    id={typology._id}
                    value={typology._id}
                    checked={selectedTypologies.some(
                      (t) => t.Id === typology._id
                    )}
                    onChange={(e) =>
                      handleTypologySelect(
                        e,
                        typology,
                        selectedTypologies,
                        setSelectedTypologies
                      )
                    }
                  />
                  <label htmlFor={typology._id}>{typology.typologyName}</label>
                </div>
              ))}
            </div>
          </section>
          <section className="related-resources resources-wrapper">
            <h5>Related Resources (optional):</h5>
            {relatedResources.map((relatedResource, index) => (
              <div key={index} className="resource-wrapper">
                <div className="field-wrapper">
                  <label>
                    <h6>Title :</h6>
                  </label>
                  <input
                    type="text"
                    placeholder="Title"
                    name="title"
                    value={relatedResource.title}
                    onChange={(event) =>
                      handleRelatedResourceTitleChange(event, index)
                    }
                  />
                </div>
                <div className="field-wrapper">
                  <label>
                    <h6>
                      Link (Enter website URL including http:// or https://):
                    </h6>
                  </label>
                  <input
                    type="text"
                    placeholder="Link"
                    name="link"
                    value={relatedResource.link}
                    onChange={(event) =>
                      handleRelatedResourceLinkChange(event, index)
                    }
                  />
                </div>
                {/* {index > 0 && ( */}
                <button
                  type="button"
                  onClick={() => handleRemoveRelatedResource(index)}
                  className="btn-secondary btn-form"
                >
                  <BsDashCircle />
                  <p className="p-detail">Remove Related Resource</p>
                </button>
                {/* )} */}
              </div>
            ))}
            <button
              type="button"
              className="btn-secondary btn-form"
              onClick={handleAddRelatedResource}
            >
              <BsPlusCircle />
              <p className="p-detail">Add Related Resource</p>
            </button>
          </section>
          <PrimaryImgSection
            primaryImageByUrl={primaryImageByUrl}
            setPrimaryImageByUrl={setPrimaryImageByUrl}
            setIsLoading={setIsLoading}
            setPrimaryImage={setPrimaryImage}
            primaryImgFileCaption={primaryImgFileCaption}
            setPrimaryImgFileCaption={setPrimaryImgFileCaption}
          />
          <OtherImgSection
            setIsLoading={setIsLoading}
            setImages={setImages}
            imagesByUrl={imagesByUrl}
            // images={images}
            setImagesByUrl={setImagesByUrl}
            otherImgFilesCaptions={otherImgFilesCaptions}
            setOtherImgFilesCaptions={setOtherImgFilesCaptions}
          />
          <section className="submitter-section">
            <h5>Submitted By:</h5>
            <div className="submitter-wrapper">
              <div className="submitter-name-wrapper">
                <div className="field-wrapper">
                  <h6>First Name:</h6>
                  <input
                    type="text"
                    name="firstName"
                    value={submittedBy.firstName}
                    onChange={(event) =>
                      handleSubmittedByChange(event, "firstName")
                    }
                    required
                  />
                </div>
                <div className="field-wrapper">
                  <h6>Last Name:</h6>
                  <input
                    type="text"
                    name="lastName"
                    value={submittedBy.lastName}
                    onChange={(event) =>
                      handleSubmittedByChange(event, "lastName")
                    }
                    required
                  />
                </div>
              </div>
              <div className="field-wrapper">
                <h6>Email Address:</h6>
                <input
                  type="email"
                  name="emailAddress"
                  value={submittedBy.emailAddress}
                  onChange={(event) =>
                    handleSubmittedByChange(event, "emailAddress")
                  }
                  required
                />
              </div>
              <div className="field-wrapper">
                <h6>Organization:</h6>
                <input
                  type="text"
                  name="organization"
                  value={submittedBy.organization}
                  onChange={(event) =>
                    handleSubmittedByChange(event, "organization")
                  }
                  required
                />
              </div>
            </div>
          </section>
          <Button
            className="btn-secondary btn-submit"
            variant="primary"
            disabled={isLoading}
            type="submit"
            onClick={handleProjectSubmit}
          >
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </form>
      )}
    </main>
  );
}
