import Navbar from "../components/Navbar";
import HorizontalProjectMenu from "../components/HorizontalProjectSummaries/HorizontalProjectMenu";
import React, { useContext, useEffect, useState } from "react";
import MarkPointOnMap from "../components/Map/MarkPointOnMap";
import ClassificationContext from "../store/ClassificationContext";
import InnovationFilterHeader from "../components/Map/InnovationFilterHeader";

export default function MapByInnovation() {
  const classificationCtx = useContext(ClassificationContext);
  let [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  //get Projects data
  useEffect(() => {
    const getProjectAPI = async () => {
      const innovationId = classificationCtx.innovationId;
      // console.log("innovation to find project", innovationId);
      await classificationCtx.getProjectsByInnovation(innovationId);
    };
    const waitLoading = async () => {
      setIsLoading(true);
      await getProjectAPI();
      setIsLoading(false);
    };
    waitLoading()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classificationCtx.innovationId]);

  // useEffect(() => {
  //   setSelectedProjectIndex(0);
  // }, [classificationCtx.innovationId]);
  useEffect(() => {
    const projects = classificationCtx?.projects;
    const projectId = classificationCtx?.projectId;
    const findIndex = () => {
      //when navigated from project page. set index
      console.log('projects', projects, projectId)
      const index = projects.findIndex((project) => project._id === projectId);
      if (index === -1) return setSelectedProjectIndex(0);
      console.log("index", index);
      setSelectedProjectIndex(index);
      //reset projectId to prevent flying to the index for the navigation from other than project page
      // classificationCtx.setProjectId();
    };
     findIndex();
    
  }, [classificationCtx.projects, classificationCtx.projectId]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <div className="container">
      {isLoading ? (
        <div className="loader" />
      ) : (
        <main className="content-container map-container">
          <>
            <InnovationFilterHeader />
            <section className="map-clickable"></section>

            <HorizontalProjectMenu
              selectedProjectIndex={selectedProjectIndex}
              setSelectedProjectIndex={setSelectedProjectIndex}
            />
            <MarkPointOnMap
              selectedProjectIndex={selectedProjectIndex}
              setSelectedProjectIndex={setSelectedProjectIndex}
            />
          </>
        </main>
      )}

      <Navbar />
    </div>
  );
}
