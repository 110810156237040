import React from "react";

import ProjectExpandableDescription from "./ProjectExpandableDescription";

const ProjectRelatedInnovations = ({ projectInnovation, projectId }) => {
  //Changing obj value to array from old codes.
  const projectInnovationArrRaw = [
    projectInnovation.primaryInnovation,
    projectInnovation.secondaryInnovation,
    projectInnovation.tertiaryInnovation,
  ];
  const projectInnovationArr = projectInnovationArrRaw.filter(
    (innovation) => innovation?.Id?.innovationName
  );
  const hasCurrentInnovations = projectInnovationArr.some(
    (typology) => typology.Id !== null
  );

  console.log("typologyArry", projectInnovationArr, hasCurrentInnovations);
  if (projectInnovationArr && hasCurrentInnovations) {
    return (
      <>
        <section className="project-relatedClassification text-container with-title">
          <h4>RELATED INNOVATIONS</h4>
          <div className="text-wrapper with-title">
            {projectInnovationArr.map((innovation, i) => {
              if (innovation.Id)
                return (
                  <ProjectExpandableDescription
                    key={`innovation-description-${i}`}
                    innovation={innovation}
                    projectId={projectId}
                  />
                );
            })}
          </div>
        </section>
      </>
    );
  }
};

export default ProjectRelatedInnovations;
