import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import GlossaryContainer from "../components/Glossary/GlossaryContainer";

export const About = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchAllTypologies = async () => {
      // await classificationCtx.getAllTypologies();
    };
    const waitLoading = async () => {
      setIsLoading(true);
      await fetchAllTypologies();
      setIsLoading(false);
    };
    waitLoading();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container">
      {isLoading ? (
        <div className="loader" />
      ) : (
        <main className="content-container project-container about-container">
          <div className="tilted-top" />
          <div className="about-padding">
            <section className="about-texts">
              <p className="about-text-pharagraph">
                <b>Yycinnovates</b> is a graphic directory of Calgary’s most
                interesting built environment innovations. Developed by the
                University of Calgary’s{" "}
                <a href="https://sapl.ucalgary.ca/" className="link-underbar">
                  School of Architecture, Planning and Landscape
                </a>
                , and currently in beta testing, <b>yycinnovates</b> reveals to
                a general public audience the many ways in which the design
                professions make our city a great place to live.
              </p>
              <p className="about-text-pharagraph">
                For the RAIC 2023 Conference, <b>yycinnovates</b> maps out the
                conference locations, walking tours, and studio crawl venues so
                you can get where you want to go while enjoying recent
                award-winning projects along the way.
              </p>
              {/* <p className="about-text-pharagraph">
                <b>yycinnovates</b> is the brainchild of SAPL’s Dean’s Circle of
                local area design firms. It was funded by the Calgary Downtown
                Association and built as a collaboration between SAPL and
                software development students. When fully loaded with innovative
                projects both big and small, <b>yycinnovates</b> will be used by
                the Calgary Downtown Association, City of Calgary, Calgary
                Economic Development, Tourism Calgary, and the Chamber of
                Commerce to promote local design culture and celebrate the too
                often unsung innovations completed by architects, planners, and
                landscape architects.
              </p> */}
            </section>
          </div>
          <div className="tilted-btm" />
        </main>
      )}
      <Navbar />
      <GlossaryContainer />
    </div>
  );
};
