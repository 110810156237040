import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import ClassificationContext from "../store/ClassificationContext";
import UCLogo from "../images/Logos/UC-vert-rgb.svg";
import SAPLWordMark from "../images/Logos/SAPL-wordmark.svg";
// import {imageSet} from 'polished'

export function Gateway() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const classificationCtx = useContext(ClassificationContext);
  const [currentImage, setCurrentImage] = useState();
  const gatewayId = classificationCtx.innovationId;
  const [isLoading, setIsLoading] = useState(true);
  // console.log("gatewayoutside", gatewayId);
  useEffect(() => {
    const fetchInnovations = () => {
      classificationCtx.getAllInnovations();
    };
    const getRandomImg = async () => {
      try {
        const response = await fetch(baseUrl + `/api/project/randomImg`);
        if (response.status !== 200) {
          throw new Error("Fetch for project summaries failed");
        }
        const randomData = await response.json();
        setCurrentImage(randomData);
        console.log("random data", randomData);
      } catch (err) {
        console.log("Error on client-side.", err);
      }
    };
    const waitTillloading = async () => {
      setIsLoading(true);
      await getRandomImg();
      setIsLoading(false);
    };
    waitTillloading();
    fetchInnovations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //background resizing
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [homeMainWidth, setHomeMainWidth] = useState(0);

  useEffect(() => {
    setHomeMainWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    // console.log('width', homeMainWidth)
    if (homeMainWidth < 430) {
      setBackgroundUrl(currentImage?.url?.primaryResizedUrl);
    } else {
      setBackgroundUrl(currentImage?.url?.primaryOriginalUrl);
    }
  }, [homeMainWidth, currentImage]);
  // Background image styles
  const backgroundStyle = {
    background: `center/cover no-repeat`,
    backgroundImage: `url(${backgroundUrl})`,
  };

  return (
    <div className="home-container">
      {isLoading ? (
         <div className="loader"/>
      ) : (
        currentImage && (
          <main className="home-main" style={backgroundStyle}>
            {/* <main className="home-main"> */}

            <section className="home-content">
              <h1 className="home-title">
                What <br /> makes <br />
                Calgary
              </h1>
              <div className="list-spacer">
                <select
                  className="list-style"
                  value={gatewayId}
                  onChange={(e) => {
                    const innovationId = e.target.value;
                    // console.log("id", innovationId);
                    classificationCtx.setInnovationId(innovationId);
                  }}
                >
                  {classificationCtx.allInnovations.map((gatewayWord, i) => {
                    return (
                      <option key={`gateway-${i}`} value={gatewayWord._id}>
                        {gatewayWord.innovationName}
                      </option>
                    );
                  })}
                </select>
                <h1 className="home-questionmark">?</h1>
              </div>
              <div className="button-spacer">
                {gatewayId ? (
                  <button className="btn-primary button-lets-go">
                    <Link to="/MapByInnovation">Let's Go</Link>
                  </button>
                ) : null}
              </div>
            </section>
          </main>
        )
      )}
      <footer className="container-btm">
        <div className="logos-container">
          <div className="main-logos-wrapper">
            <div className="logo-wrapper left">
              <img className="logo" src={UCLogo} alt="UC logo" />
            </div>
            <div className="logo-wrapper">
              <img className="logo" src={SAPLWordMark} alt="SAPL wordmark" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
