import { createContext } from "react";

const GlossaryContext = createContext({
  allProjects: [],

  showGlossary: undefined
  
});

export default GlossaryContext;
