import React, { useState } from "react";

function PrimaryImgSection({
  primaryImageByUrl,
  setPrimaryImageByUrl,
  setIsLoading,
  setPrimaryImage,
  primaryImgFileCaption,
  setPrimaryImgFileCaption,
}) {
  const [previewImage, setPreviewImage] = useState();
  const handlePrimaryImageUpload = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (file.size > 6 * 1240 * 1240) {
      alert("Please upload images less than 6MB");
      setIsLoading(false);
      return;
    }
    if (!allowedTypes.includes(file.type)) {
      alert("Please upload only image files (JPEG, PNG, GIF).");
      return setIsLoading(false);
    }

    setPrimaryImage(file);
    const previewImage = URL.createObjectURL(file);

    // console.log(previewImages);
    setPreviewImage(previewImage);
  };
  const changePrimaryUploadOpt = (e) => {
    const uploadType = e.target.value;

    if (uploadType === "file") {
      setPrimaryImageByUrl();
    } else if (uploadType === "url") {
      setPrimaryImage();
      setPrimaryImageByUrl({ captionType: "imageSource" });
      setPrimaryImgFileCaption();
      setPreviewImage();
    }
  };

  // const changePrimaryCaptionOpt = (e) => {
  //   const captionType = e.target.value;
  //   // setImgCaptionType(captionType);
  //   setPrimaryImageByUrl({
  //     // uploadType: primaryImageByUrl.uploadType,
  //     url: primaryImageByUrl.url,
  //     captionType,
  //   });
  // };
  const handleImageCaption = (e) => {
    const value = e.target.value;

    // console.log("changeing caption",
    // );
    setPrimaryImgFileCaption({
      captionType: "photoCredit",
      photoCredit: value,
    });
  };
  return (
    <section className="image-section">
      <label>
        <h5>Please upload the primary image of the structure (required):</h5>
        <div className="upload-type-wrapper">
          <p className="text-ss">Upload type :</p>

          <div className="radio-types">
            <label className="text-ss">
              <input
                type="radio"
                name="uploadType"
                value="file"
                checked={!primaryImageByUrl}
                onChange={(e) => {
                  changePrimaryUploadOpt(e);
                }}
              />{" "}
              File(&lt;6MB)
            </label>
            <label className="text-ss">
              <input
                type="radio"
                name="uploadType"
                value="url"
                checked={primaryImageByUrl || false}
                onChange={(e) => {
                  changePrimaryUploadOpt(e);
                }}
              />{" "}
              URL
            </label>
          </div>
        </div>
      </label>
      {primaryImageByUrl && (
        <div className="option-fields-wrapper">
          <div className="field-wrapper">
            <label>
              <p className="text-ss">
                Image URL (If the URL is valid, a preview of the image will be
                displayed below.):
              </p>
            </label>
            <input
              type="text"
              value={primaryImageByUrl?.url || ""}
              onChange={(e) =>
                setPrimaryImageByUrl({
                  ...primaryImageByUrl,
                  url: e.target.value,
                })
              }
            />
          </div>
          <div className="img-source-conatiner fields-wrapper">
            <h6>
              Caption (You can include the source URL or photo credit. At least
              one of these is required.):
            </h6>
            {/* <div className="upload-type-wrapper">
              <p className="text-ss">Caption type :</p>

              <div className="radio-types">
                <label className="text-ss">
                  <input
                    type="radio"
                    name="captionType"
                    value="imageSource"
                    checked={primaryImageByUrl.captionType === "imageSource"}
                    onChange={(e) => {
                      changePrimaryCaptionOpt(e);
                    }}
                  />{" "}
                  Image source url
                </label>
                <label className="text-ss">
                  <input
                    type="radio"
                    name="captionType"
                    value="photoCredit"
                    checked={primaryImageByUrl.captionType === "photoCredit"}
                    onChange={(e) => {
                      changePrimaryCaptionOpt(e);
                    }}
                  />{" "}
                  Photo credit
                </label>
              </div>
            </div> */}
            <div className="img-sources-wrapper">
              {/* {primaryImageByUrl.captionType === "imageSource" && ( */}
                <div className="field-wrapper">
                  <label>
                    <p className="text-ss">
                      Image source url (Enter website URL including http:// or
                      https://):
                    </p>
                  </label>
                  <input
                    type="text"
                    value={primaryImageByUrl?.imageSource || ""}
                    onChange={(e) =>
                      setPrimaryImageByUrl({
                        ...primaryImageByUrl,
                        imageSource: e.target.value,
                      })
                    }
                  />
                </div>
              {/* )} */}
              {/* {primaryImageByUrl.captionType === "photoCredit" && ( */}
                <div className="field-wrapper">
                  <label>
                    <p className="text-ss">Photo Credit:</p>
                  </label>
                  <input
                    type="text"
                    value={primaryImageByUrl?.photoCredit || ""}
                    onChange={(e) =>
                      setPrimaryImageByUrl({
                        ...primaryImageByUrl,
                        photoCredit: e.target.value,
                      })
                    }
                  />
                </div>
              {/* )} */}
            </div>
          </div>
          <div className="field-wrapper">
            {primaryImageByUrl?.url && (
              <img
                alt="primaryImgByUrl"
                width="50%"
                src={primaryImageByUrl?.url}
              />
            )}
          </div>
        </div>
      )}
      {!primaryImageByUrl && (
        <div className="option-fields-wrapper">
          <input
            type="file"
            accept="image/*"
            onChange={handlePrimaryImageUpload}
            // requiredin
          />
          {previewImage && (
            <div className="image-previews-container">
              <figure>
                <img
                  className="img-preview-form"
                  src={previewImage}
                  alt="preview"
                />
                <figcaption className="text-ssx">
                  <label>Photo credit (optional):</label>
                  <input
                    type="text"
                    className="img-preview-input"
                    onChange={(e) => handleImageCaption(e)}
                    value={primaryImgFileCaption?.photoCredit || ""}
                  />
                </figcaption>
              </figure>
            </div>
          )}
        </div>
      )}
    </section>
  );
}

export default PrimaryImgSection;
