/* eslint-disable */
// import mapboxgl from "mapbox-gl";
import mapboxgl from "!mapbox-gl";
import React, { useRef, useEffect, useState, useContext } from "react";
import ClassificationContext from "../../store/ClassificationContext";

import "../../styles/MarkPointOnMap.css";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function MarkPointOnMap({ setSelectedProjectIndex, selectedProjectIndex }) {
  // let glossaryContext = useContext(GlossaryContext);
  const mapContainer = useRef(null);
  const classificationCtx = useContext(ClassificationContext);
  const features = Array.from(classificationCtx.projects);
  const [map, setMap] = useState(null);
  const [containerOffset, setContainerOffset] = useState({ x: 0, y: 0 });
  // const mapContainer = useRef(null);
  const zoom = useRef(null);

  const geolocationControl = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: false,
    showUserLocation: true,
    showAccuracyCircle: true,
    showUserHeading: true,
  });
  //changing index to 0 when gateword is changed.
  // useEffect(() => {
  //   console.log('here')
  //   setSelectedProjectIndex(0);
  // }, [classificationCtx.gdd]);
  //listen zoom level change to center coordinate
  useEffect(() => {
    const onZoomListner = () => {
      zoom.current = map?.getZoom();
      const listener = () => {
        zoom.current = map?.getZoom();
      };
      map?.on("zoom", listener);
      return () => map?.off("zoom", listener);
    };
    onZoomListner();
    // console.log('map', map)

    geolocationControl.on("geolocate", (e) => {
      const { longitude, latitude } = e.coords;
      map?.flyTo({
        center: [longitude + containerOffset.x, latitude + containerOffset.y],
        zoom: 50,
      });
      // console.log("userlocation", e);
    });
  });
  // center adjustment to make the coordinate center in the map active area
  useEffect(() => {
    const getContainerOffset = () => {
      const clickableArea = document
        .querySelector(".map-clickable")
        .getBoundingClientRect();
      const mapArea = document.querySelector(".map").getBoundingClientRect();
      if (map) {
        //pixel offset between background map and active map area
        const offsetPxX = clickableArea.left - mapArea.left;
        const offsetPxY = clickableArea.top - mapArea.top;
        //Disired center of map pixel location on screen
        const centerPxX = mapArea.width / 2 + offsetPxX;
        const centerPxY = mapArea.height / 2 + offsetPxY;
        //Pixel to geolocation coordinate
        const newCenter = map.unproject([centerPxX, centerPxY]);
        // get original center of the background map
        const originalCenter = map.getCenter();
        // Offsets
        const offsetX = -(originalCenter.lng - newCenter.lng);
        const offsetY = -(originalCenter.lat - newCenter.lat);
        setContainerOffset({ x: offsetX, y: offsetY });
      }
    };
    getContainerOffset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, zoom.current]);

  useEffect(() => {
    let pins = [];

    if (map) {
      //pin
      features.forEach((feature, index) => {
        const coordinates = feature.location.coordinates
        // console.log(coordinates)
        if(coordinates.length===0)return;
        let el = document.createElement("div");
        pins.push(el);
        if (index === selectedProjectIndex) {
          el.className = `marker red-marker`;
        } else {
          el.className = `marker`;
        }
        new mapboxgl.Marker({ anchor: "bottom", element: el })
          .setLngLat(coordinates)
          .addTo(map);
        el.addEventListener("click", () => {
          setSelectedProjectIndex(index);
        });
      });
    }
    const cleanup = () => {
      pins.forEach((pin) => pin.remove());
    };
    return cleanup;
  }, [features, selectedProjectIndex, map]);

  useEffect(() => {
    // center the selected project
    const getCenter =()=>{
      const markerCenter = features[selectedProjectIndex]?.location?.coordinates;
      console.log('center', markerCenter)
      if(!markerCenter||markerCenter?.length===0)return;
      // console.log('here')
      map &&map.easeTo({
        center: [
          markerCenter[0] + containerOffset.x,
          markerCenter[1] + containerOffset.y/2,
        ],
        duration: 800,
      });
    }
    features.length!==0&&getCenter()
  }, [map, features, selectedProjectIndex, containerOffset]);

  useEffect(() => {
    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: process.env.REACT_APP_MAPBOX_STYLE_URL,
      // center: [-114.066666, 51.049999],
      zoom: 12,
      attributionControl: true,
    });

    // Add zoom and rotation controls to the map.
    newMap.addControl(
      new mapboxgl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true,
      })
    );

    // Add geolocate control to the map.
    newMap.addControl(geolocationControl);

    setMap(newMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classificationCtx.projects]);

  return <div className="map lg" ref={mapContainer} />;
}

export default MarkPointOnMap;
