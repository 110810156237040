import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Gateway } from "./pages/Gateway";
import ProjectPage from "./pages/ProjectPage";
import ClassificationProvider from "./store/ClassificationProvider";
import GlossaryProvider from "./store/GlossaryProvider";
// import { ProjectForm } from "./pages/form";

import "./styles/Styles.css";
import HeaderWithLogo from "./components/HeaderWithLogo";
import ShowTopLogos from "./components/ShowTopLogos";
import MapByTypology from "./pages/MapByTypology";
import Explore from "./pages/Explore";
import { lazy, Suspense } from "react";

import MapByInnovation from "./pages/MapByInnovation";
import FormLogin from "./pages/FormLogin";
import { About } from "./pages/About";
const AdminLogin = lazy(() => import("./pages/Admin/AdminLogin"));


function App() {
  return (
    <ClassificationProvider>
      <GlossaryProvider>
        <BrowserRouter>
          <ShowTopLogos>
            <HeaderWithLogo />
          </ShowTopLogos>
          <Routes>
            <Route path="/form" element={<FormLogin/>} />
            <Route path="/" exact element={<Gateway />} />
            <Route path="/About" element={<About/>} />
            <Route path="/Explore" element={<Explore />} />
            <Route path="/MapByInnovation" element={<MapByInnovation />} />
            <Route path="/MapByTypology" element={<MapByTypology/>} />
            <Route path="/ProjectPage/:id" element={<ProjectPage />} />
            <Route path="/Admin" element={<Navigate replace to="/Admin/Projects/list"/>}/>
            <Route
              path="/Admin/*"
              element={
                <Suspense fallback={<div>loading...</div>}>
                  <AdminLogin />
                </Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </GlossaryProvider>
    </ClassificationProvider>
  );
}

export default App;
