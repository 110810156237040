import axios from "axios";

export const fetchInnovations = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const response = await axios.get(baseUrl+"/api/innovation"); //
    const innovations = response.data;
    // console.log("data from innovation", innovations);
    return innovations;
  } catch (error) {
    console.error("Error fetching innovations:", error);
    return [];
  }
};

export const handleInnovationSelect = (
  event,
  innovationTypeKey,
  innovations,
  setInnovationData
) => {
  const selectedInnovationId = event.target.value;
  const selectedInnovation = innovations.find(
    (innovation) => innovation._id === selectedInnovationId
  );

  if (selectedInnovation) {
    setInnovationData((prevData) => ({
      ...prevData,
      innovationType: {
        ...prevData.innovationType,
        [innovationTypeKey]: {
          Id: selectedInnovation._id,
          innovationDescription: "",
        },
      },
    }));
  } else {
    setInnovationData((prevData) => ({
      ...prevData,
      innovationType: {
        ...prevData.innovationType,
        [innovationTypeKey]: {
          Id: null, // Set to null instead of an empty string
          innovationDescription: "",
        },
      },
    }));
  }
};
