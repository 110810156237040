import React, { useContext, useEffect, useState } from "react";
import HorizontalProjectMenu from "../components/HorizontalProjectSummaries/HorizontalProjectMenu";
import MarkPointOnMap from "../components/Map/MarkPointOnMap";
import Navbar from "../components/Navbar";
import ClassificationContext from "../store/ClassificationContext";
import TypologyFilterHeader from "../components/Map/TypologyFilterHeader";

const MapByTypology = () => {
  const classificationCtx = useContext(ClassificationContext);
  let [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchAllTypologies = async () => {
      await classificationCtx.getAllTypologies();
    };
    const getProjectAPI = async () => {
      const typologyId = classificationCtx.typologyId;
      await classificationCtx.getProjectsByTypology(typologyId);
    };
    const waitLoading = async () => {
      setIsLoading(true);
      await fetchAllTypologies();
      await getProjectAPI();
      setIsLoading(false);
    };
    waitLoading()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classificationCtx.typologyId]);

  // useEffect(() => {
  //   setSelectedProjectIndex(0);
  //   console.log('setting to 0 ')
  // }, [classificationCtx.typologyId]);
  useEffect(() => {
    console.log('project index', selectedProjectIndex)
  },[selectedProjectIndex])
  useEffect(() => {
    const projects = classificationCtx?.projects;
    const projectId = classificationCtx?.projectId;
    const findIndex = () => {
      // console.log('projectId', projectId)
      const index = projects.findIndex((project) => project._id === projectId);
      // console.log('index', index)
      if (index === -1) return setSelectedProjectIndex(0);
      setSelectedProjectIndex(index);
      // classificationCtx.setProjectId();
    };
   findIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classificationCtx.projects, classificationCtx.projectId]);
  return (
    <div className="container">
      {isLoading ? (
        <div className="loader" />
      ) : (
        <main className="content-container map-container">
          <>
            <TypologyFilterHeader />
            <section className="map-clickable"></section>
            <HorizontalProjectMenu
              selectedProjectIndex={selectedProjectIndex}
              setSelectedProjectIndex={setSelectedProjectIndex}
            />
            <MarkPointOnMap
              selectedProjectIndex={selectedProjectIndex}
              setSelectedProjectIndex={setSelectedProjectIndex}
            />
          </>
        </main>
      )}

      <Navbar />
    </div>
  );
};
export default MapByTypology;
