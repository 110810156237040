import React, { useContext } from "react";
import { Link } from "react-router-dom";
import GlossaryContainer from "./Glossary/GlossaryContainer";
import GlossaryContext from "../store/GlossaryContext";

import {
  TiHomeOutline,
  TiMap,
  TiImage,
  TiSortAlphabetically,
} from "react-icons/ti";
import { HiMagnifyingGlass } from "react-icons/hi2";
import ClassificationContext from "../store/ClassificationContext";
// import "../styles/Styles.css";

export default function Navbar() {
  let glossaryContext = useContext(GlossaryContext);
  const classificationContext = useContext(ClassificationContext)
  return (
    <>
      <div
        className="nav-container"
        // {`nav-container ${
        //   glossaryContext.showGlossary ? "display-none" : "display-block"
        // }`}
      >
        <Link to="/" className="nav-links p-detail">
          <TiHomeOutline />
          Home
        </Link>
        <Link
          to="/MapByInnovation"
          onClick={() => {
            classificationContext.setProjectId();
          }}
          className="nav-links p-detail"
        >
          <TiMap />
          Map
        </Link>
        <Link to="/Explore" className="nav-links p-detail">
          <TiImage />
          Explore
        </Link>
        <div
          className="nav-links p-detail"
          onClick={
            () => glossaryContext.setShowGlossary(true)
            //  && setNavbar(false)
          }
        >
          <TiSortAlphabetically />
          Projects
        </div>
        <Link to="/About" className="nav-links p-detail">
          <HiMagnifyingGlass />
          About
        </Link>
      </div>

      {glossaryContext.showGlossary ? <GlossaryContainer /> : null}
    </>
  );
}
