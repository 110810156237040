import React from "react";

const DescriptionReferenceSection = ({ projectReference, setProjectReference }) => {
  return (
    <div className="reference-container">
      <p className="text-ss">Reference (optional) : </p>
      <div className="reference-fields-wrapper">
        <div className="reference-field-wrapper">
          <label>
            <p className="text-ss">
              URL (Enter website URL including http:// or https://):
            </p>
          </label>
          <input
            type="text"
            value={projectReference?.url || ""}
            onChange={(e) =>
              setProjectReference({
                ...projectReference,
                url: e.target.value,
              })
            }
          />
        </div>

        <div className="field-wrapper-line">
          <label>
            <p className="text-ss">Text:</p>
          </label>
          <input
            type="text"
            value={projectReference?.text || ""}
            onChange={(e) =>
              setProjectReference({
                ...projectReference,
                text: e.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DescriptionReferenceSection;
