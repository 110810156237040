import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const ShowTopLogos = ({children}) => {

    const location = useLocation();
    const [showLogos, setShowLogos] = useState(false);

    useEffect(()=>{
        if(location.pathname==='/'){
            setShowLogos(false);
        }else{
            setShowLogos(true);
        }
    },[location])

  return (
    <>{showLogos&&children}</>
  )
}

export default ShowTopLogos