import React from "react";

function ProjectCollaboratorsOwner({ owner, collaborators }) {
  // console.log("collaborators and owners", collaborators, owner);
  const ownerWithLink = () => {
    const underbarClass = owner?.website ? "link-underbar" : "";
    return (
      <a
        className={`${underbarClass}`}
        href={
          owner.website &&
          (owner.website.includes("https")||owner.website.includes("http")
            ? owner.website
            : `https://${owner?.website}`)
        }
      >
        {owner?.name}
      </a>
    );
  };
  // href={designer.website &&(designer?.website.includes("https://")? designer.website: `https://${designer.website}`)}
  return (
    <>
      {collaborators && (
        <>
          <h4>OWNER</h4>
          <ul>
            <li>{ownerWithLink()}</li>
          </ul>
          {collaborators.length !== 0 && (
            <>
              <h4>OTHER CONTRIBUTORS</h4>
              <ul>
                <li>
                  {collaborators.map((collaborator, i) => {
                    const underbarClass = collaborator?.website
                      ? "link-underbar"
                      : "";
                    if (i === 0) {
                      return (
                        <a
                          key={`collaborator-${i}`}
                          className={`${underbarClass}`}
                          href={
                            collaborator.website &&
                            (collaborator.website.includes("https")||collaborator.website.includes("http")
                              ? collaborator.website
                              : `https://${collaborator?.website}`)
                          }
                        >
                          {collaborator?.name}
                        </a>
                      );
                    } else {
                      return (
                        <span key={`collaborator-${i}`}>
                          <span>, </span>
                          <a
                            className={`${underbarClass}`}
                            href={
                              collaborator.website &&
                              (collaborator.website.includes("https")||collaborator.website.includes("http")
                                ? collaborator.website
                                : `https://${collaborator?.website}`)
                            }
                          >
                            {collaborator?.name}
                          </a>
                        </span>
                      );
                    }
                  })}
                </li>
              </ul>
            </>
          )}
        </>
      )}

      {/* <h2>
          {designers.length !== 0 &&
            designers.map((designer, i) => {
              if (i === 0) {
                return (
                  <a
                    key={`designer-${i}`}
                    className="link-underbar"
                    href={designer.website}
                  >
                    {designer.name}
                  </a>
                );
              } else {
                return (
                  <>
                    <span>, </span>
                    <a
                      key={`designer-${i}`}
                      className="link-underbar"
                      href={designer.website}
                    >
                      {designer.name}
                    </a>
                  </>
                );
              }
            })}
        </h2> */}
    </>
  );
}

export default ProjectCollaboratorsOwner;
