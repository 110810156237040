import { Swiper, SwiperSlide } from "swiper/react";

import { EffectCoverflow, Mousewheel, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { useContext, useEffect, useRef } from "react";
import ClassificationContext from "../../store/ClassificationContext";
import { Link } from "react-router-dom";

const ProjectSlider = ({
  slides,
  selectedProjectIndex,
  setSelectedProjectIndex,
}) => {
  const classificationCtx = useContext(ClassificationContext);
  const swiperRef = useRef(null);
  const features = Array.from(slides);

  console.log("slider in project page", slides);
  const changeClassificationCtx = (id) => {
    classificationCtx.setProjectId(id);
  };

  //swiper triggered project index change for the map
  const swiperTriggeredChange = (swiper) => {
    const activeIndex = swiper.realIndex;
    if (activeIndex !== selectedProjectIndex) {
  
      setSelectedProjectIndex(activeIndex);
    }
  };

  //when map triggered to go to selected slide
  useEffect(() => {
    const swiper = swiperRef.current.swiper;
    // console.log("maptrigered", "maptriggered index", mapTriggeredIndex, "swiper index", swiper.realIndex);
    if (swiper && selectedProjectIndex >= 0) {
      // console.log('maptriggered index', selectedProjectIndex)
      swiper.slideTo(selectedProjectIndex);
    }
  }, [selectedProjectIndex]);
  // console.log('project', features, selectedProjectIndex)
  return (
    <div className="slider-container">
      <div className="slider-title-wrapper">
        <Link to={"/ProjectPage/" + features[selectedProjectIndex]?._id}>
          <h5
            className="slider-title"
            onClick={() => {
              changeClassificationCtx(features[selectedProjectIndex]?._id);
            }}
          >
            {features.length !== 0 &&
              features[selectedProjectIndex]?.projectName.toUpperCase()}
          </h5>
        </Link>
      </div>
      <Swiper
        ref={swiperRef}
        modules={[EffectCoverflow, Mousewheel, Navigation]}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
          slideShadows: true,
        }}
        mousewheel={{ invert: true, sensitivity: 2, releaseOnEdges: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        onSlideChange={(swiper) => {
          swiperTriggeredChange(swiper);
        }}
        className="related-project-swiper"
      >
        {features?.map((project, index) => {
          return (
            <SwiperSlide
              key={`slide-${index}`}
              className="related-project-swiper-slide"
            >
              <a
                href={"/ProjectPage/" + project._id}
                onClick={() => {
                  changeClassificationCtx(project._id);
                }}
              >
                {!project.primaryImageByUrl&&project.primaryImage && (
                  <img
                    key={`img-${index}`}
                    src={project.primaryImage?.primaryResizedGalleryUrl}
                    alt="related-projectimage"
                  />
                )}
                {project.primaryImageByUrl && (
                  <img
                    key={`img-${index}`}
                    src={project.primaryImageByUrl.url}
                    alt="related-projectimage"
                  />
                )}
              </a>
            </SwiperSlide>
          );
        })}
        <div className="slider-controller">
          <div className="swiper-button-prev slider-arrow"></div>
          <div className="swiper-button-next slider-arrow"></div>
        </div>
      </Swiper>
    </div>
  );
};

export default ProjectSlider;
