import { useContext, useEffect, useState } from "react";
import TypologyCard from "../components/Explore/TypologyCard";
import Navbar from "../components/Navbar";
import ClassificationContext from "../store/ClassificationContext";

export default function Explore() {
  const classificationCtx = useContext(ClassificationContext);
  const typologies = classificationCtx.allTypologies;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchAllTypologies = async () => {
      await classificationCtx.getAllTypologies();
    };
    const waitLoading = async () => {
      setIsLoading(true);
      await fetchAllTypologies();
      setIsLoading(false);
    };
    waitLoading()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // console.log("classifications in Explore page", classifications);
  const containerStyle = {
    //bringing static map with monochrome style map.
    background: `center/cover no-repeat url(${`https://api.mapbox.com/styles/v1/sapl123/clfju3odb000t01o7rodqxvju/static/-114.066666,51.049999,11,0,0/1280x1280?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`})`,
  };

  return (
    <div className="container">
      {isLoading ? (
        <div className="loader" />
      ) : (
        <main className="content-container" style={containerStyle}>
          <div className="explore-container">
            <h3>EXPLORE</h3>
            <section className="explore-cards-wrapper">
              {typologies &&
                typologies.map((typology, i) => {
                  return (
                    <TypologyCard
                      key={`classification-card-${i}`}
                      typology={typology}
                    />
                  );
                })}
            </section>
          </div>
        </main>
      )}
      <Navbar />
    </div>
  );
}
