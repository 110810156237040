import { useContext } from "react";
import ClassificationContext from "../../store/ClassificationContext";
// import "../styles/Styles.css";

export default function InnovationFilterHeader() {
  const classificationCtx = useContext(ClassificationContext);
  const innovationId = classificationCtx.innovationId;

  // console.log("innovation context", classificationCtx.allInnovations ,gatewayId);
//when page is refreshed, find the g
  return (
    <section className="header-container">
      <h4>Making Calgary </h4>
      <select
        className="list-style sm"
        value={innovationId}
        onChange={(e) => {
          const selectedGatewayId = e.target.value;
          classificationCtx.setInnovationId(selectedGatewayId);
          classificationCtx.setProjectId(null);
          console.log('set project Id', classificationCtx.projectId)
        }}
      >
        {classificationCtx.allInnovations.map((innovation, i) => {
          return (
            <option key={`innovation-${i}`} value={innovation._id}>
              {innovation.innovationName}
            </option>
          );
        })}
      </select>
    </section>
  );
}
