import React, { useContext } from "react";
import ProjectSlider from "./ProjectSlider";
import ClassificationContext from "../../store/ClassificationContext";

function HorizontalProjectMenu({
  selectedProjectIndex,
  setSelectedProjectIndex,

}) {
  const classificationCtx = useContext(ClassificationContext);
  return (
    <section className="map-carousel-container">
       {classificationCtx.projects?.length !== 0 ? (
      <ProjectSlider
        slides={classificationCtx.projects}
        selectedProjectIndex={selectedProjectIndex}
        setSelectedProjectIndex={setSelectedProjectIndex}
      />
      ) : (
        <div>No project found</div>
      )}
    </section>
  );
}

export default HorizontalProjectMenu;
