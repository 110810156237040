import React, { useEffect, useState } from "react";
import { BsPlusCircle, BsDashCircle } from "react-icons/bs";

function OtherImgSection({
  setIsLoading,
  setImages,
  // images,
  imagesByUrl,
  setImagesByUrl,
  otherImgFilesCaptions,
  setOtherImgFilesCaptions,
}) {
  const [previewImages, setPreviewImages] = useState([]);
  const addImageByUrl = () => {
    setImagesByUrl([...imagesByUrl, { url: "", captionType: "imageSource" }]);
  };
  const removeImageByUrl = (index) => {
    const newImageByUrl = [...imagesByUrl];
    newImageByUrl.splice(index, 1);
    setImagesByUrl(newImageByUrl);
  };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

    if (files.length > 9) {
      alert("Please upload 9 or less images");
      return setIsLoading(false);
    }

    for (let i = 0; i < files.length; i++) {
      if (!allowedTypes.includes(files[i].type)) {
        alert("Please upload only image files (JPEG, PNG, GIF).");
        setIsLoading(false);
        return;
      }
      if (files[i].size > 6 * 1240 * 1240) {
        alert("Please upload images less than 6MB");
        setIsLoading(false);
        return;
      }
    }
    setImages(Array.from(files));
    const previewImages = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    // console.log(previewImages);
    setPreviewImages(previewImages);
  };
  const handleImgUrl = (e, index) => {
    const newImageByUrl = [...imagesByUrl];
    newImageByUrl[index].url = e.target.value;
    setImagesByUrl(newImageByUrl);
  };
  const handleImgSource = (e, index) => {
    const newImageByUrl = [...imagesByUrl];
    newImageByUrl[index].imageSource = e.target.value;
    setImagesByUrl(newImageByUrl);
  };
  const handleImgCredit = (e, index) => {
    const newImageByUrl = [...imagesByUrl];
    newImageByUrl[index].photoCredit = e.target.value;
    setImagesByUrl(newImageByUrl);
  };
  const changeCaptionOpt = (e, index) => {
    const captionType = e.target.value;
    const newImageByUrl = [...imagesByUrl];
    newImageByUrl[index] = { url: imagesByUrl[index].url, captionType };
    setImagesByUrl(newImageByUrl);
  };

  useEffect(() => {
    console.log("images", imagesByUrl);
  }, [imagesByUrl]);
  useEffect(() => {
    console.log("image captions", otherImgFilesCaptions);
  }, [otherImgFilesCaptions]);
  // const previewImages = () => {
  //   images.map((img) => {
  //     console.log("image", img)
  //     // const file = new File([img], img.name,{ type:img.type})
  //     const url = URL.createObjectURL(img);
  //     console.log('url', url)
  //     return <img src={url} alt="preview"/>
  //   });
  // };
  const handleImageCaption = (e, index) => {
    const value = e.target.value;
    const newOtherImgFilesCaptions = [...otherImgFilesCaptions];
    newOtherImgFilesCaptions[index] = {
      captionType: "photoCredit",
      photoCredit: value,
    };
    console.log("changeing caption", newOtherImgFilesCaptions);
    setOtherImgFilesCaptions(newOtherImgFilesCaptions);
  };
  return (
    <section className="image-section">
      <label>
        <h5>Please upload other images of the structure (optional):</h5>
      </label>
      <div className="image-field">
        <h6>
          Files (Each image should be less than 6MB, up to 9 images in total):
        </h6>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
        />
        <div className="image-previews-container">
            {previewImages.map((url, index) => (
              <figure key={`figure-img-${index}`}>
                <img
                  className="img-preview-form"
                  key={index}
                  src={url}
                  alt="preview"
                />
                <figcaption className="text-ssx">
                  <label>Photo credit (optional):</label>
                  <input
                    type="text"
                    className="img-preview-input"
                    onChange={(e) => handleImageCaption(e, index)}
                    value={otherImgFilesCaptions[index]?.photoCredit || ""}
                  />
                </figcaption>
              </figure>
            ))}
        </div>
      </div>
      <div className="image-field">
        <h6>URLs :</h6>
        {imagesByUrl.map((img, index) => (
          <div key={`image=${index}`} className="collaborator-wrapper">
            <div className="field-wrapper">
              <div className="option-fields-wrapper">
                <div className="field-wrapper">
                  <label>
                    <p className="text-ss">
                      Image URL (If the URL is valid, a preview of the image
                      will be displayed below.):
                    </p>
                  </label>
                  <input
                    type="text"
                    value={img.url || ""}
                    onChange={(e) => handleImgUrl(e, index)}
                  />
                </div>
                <div className="img-source-conatiner fields-wrapper">
                  <p className="text-ss">
                    Caption (You can include the source URL or photo credit. At
                    least one of these is required.):
                  </p>
                  {/* <div className="upload-type-wrapper">
                    <p className="text-ss">Caption type :</p>

                    <div className="radio-types">
                      <label className="text-ss">
                        <input
                          type="radio"
                          name={`captionType-${index}`}
                          value="imageSource"
                          checked={img.captionType === "imageSource"}
                          onChange={(e) => {
                            changeCaptionOpt(e, index);
                          }}
                        />{" "}
                        Image source url
                      </label>
                      <label className="text-ss">
                        <input
                          type="radio"
                          name={`captionType-${index}`}
                          value="photoCredit"
                          checked={img.captionType === "photoCredit"}
                          onChange={(e) => {
                            changeCaptionOpt(e, index);
                          }}
                        />{" "}
                        Photo credit
                      </label>
                    </div>
                  </div> */}
                  <div className="img-sources-wrapper">
                    {/* {img.captionType === "imageSource" && ( */}
                      <div className="field-wrapper">
                        <label>
                          <p className="text-ss">
                            Image source url (Enter website URL including
                            http:// or https://):
                          </p>
                        </label>
                        <input
                          type="text"
                          value={img.imageSource || ""}
                          onChange={(e) => handleImgSource(e, index)}
                        />
                      </div>
                    {/* )} */}
                    {/* {img.captionType === "photoCredit" && ( */}
                      <div className="field-wrapper">
                        <label>
                          <p className="text-ss">Photo Credit:</p>
                        </label>
                        <input
                          type="text"
                          value={img.photoCredit || ""}
                          onChange={(e) => handleImgCredit(e, index)}
                        />
                      </div>
                    {/* )} */}
                  </div>
                </div>
                <div className="field-wrapper">
                  {img.url && (
                    <img alt="primaryImgByUrl" width="50%" src={img.url} />
                  )}
                </div>
              </div>
            </div>
            {/* {index > 0 && ( */}
            <button
              type="button"
              onClick={() => removeImageByUrl(index)}
              className="btn-secondary btn-form"
            >
              <BsDashCircle />
              <p className="p-detail">Remove image URL</p>
            </button>
            {/* )} */}
          </div>
        ))}
      </div>
      <button
        type="button"
        className="btn-secondary btn-form btn-add-url"
        onClick={addImageByUrl}
      >
        <BsPlusCircle />
        <p className="p-detail">Add image URL</p>
      </button>
    </section>
  );
}

export default OtherImgSection;
