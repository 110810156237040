import { useContext } from "react";
import ClassificationContext from "../../store/ClassificationContext";

export default function TypologyFilterHeader() {
  const classificationCtx = useContext(ClassificationContext);
  const allTypologies = classificationCtx.allTypologies;
  const typologyId = classificationCtx.typologyId;

  return (
    
    <section className="header-container map-classification-header">
      <h4>Explore</h4>
      <select
        className="list-style sm typology-list-style"
        value={typologyId}
        onChange={(e) => {
          const selectedTypologyId = e.target.value;

          classificationCtx.setTypologyId(selectedTypologyId);
        }}
      >
        {allTypologies &&
          allTypologies.map((typology, i) => {
            return (
              <option
                value={typology._id}
                key={`typology-filter-${i}`}
              >
                {typology.typologyName}
              </option>
            );
          })}
      </select>
    </section>
  );
  // }
}
