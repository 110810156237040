import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function Geocoding({ setLocation, location }) {
  const [searchbar, setSearchbar] = useState(null);
  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      trackProximity: true,
      countries: "ca",
      language: "en",
    });

    
    geocoder.addTo(".address-search");
    setSearchbar(geocoder);
    return () => {
      searchbar?.off("result");
    };
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchbar) return;
     // Access the input element and add the 'required' attribute
 
    //  const inputElement = document.querySelector('.mapboxgl-ctrl-geocoder--input');
    //  console.log('location', location.coordinates, inputElement.value)
    //  if (!inputElement&&(!location.coordinates||location.coordinates.length===0)) {
    //    inputElement.required = true;
    //   }
      searchbar?.on("clear", (e) => setLocation({}));
   
    //structure a location object
    searchbar?.on(
      "result",
      (e) => {
        setLocation({}); //clean location data
        //result formating
        const location = e.result;
        const locationObj = {
          address: location.place_name,
          coordinates: location.geometry.coordinates,
          type: location.geometry.type,
        };

        if (location.id.includes("place")) {
          locationObj.city = location.text;
        }
        location.context.forEach((obj) => {
          if (obj.id.includes("postcode")) locationObj.postalCode = obj.text;
          if (obj.id.includes("place")) locationObj.city = obj.text;

          if (obj.id.includes("region")) locationObj.province = obj.text;
        });
        //delete city province, postalcode from address
        const address = location.place_name;
        const addressArr = address.split(", ");
        const cityIndex = addressArr.findIndex(
          (e) => e.trim().toLowerCase() === locationObj.city.toLowerCase()
        );
     
        if (cityIndex !== -1) {
          const trimmedAddress = addressArr
            .slice(0, cityIndex)
            .join(",")
            .trim();
          locationObj.address = trimmedAddress;
        }
        // console.log('location in Geocoding', locationObj)
        setLocation(locationObj); // set data
      },
      []
    );
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchbar]);

  return <div className="address-search" />;
}

export default Geocoding;
