import React from "react";

const InnovationReferenceSection = ({
  innovationType,
  innovationData,
  setInnovationData,
}) => {
  console.log("ref innovation", innovationData, innovationType);
  const handleInnovationUrlRef = (e) => {
    const value = e.target.value;

    const innovation = innovationData?.innovationType[innovationType];
    // console.log("pr", innovation);
    const innovationReference = innovation?.reference;
    const newReference = { ...innovationReference, url: value };
    const newInnovation = { ...innovation, reference: newReference };
    // console.log("newIn", innovation, newInnovation);
    setInnovationData((pre) => {
      return {
        innovationType: {
          ...pre?.innovationType,
          [innovationType]: newInnovation,
        },
      };
    });
  };
  const handleInnovationTextRef = (e) => {
    const value = e.target.value;
    const innovation = innovationData?.innovationType[innovationType];
    const innovationReference = innovation?.reference;
    const newReference = { ...innovationReference, text: value };
    const newInnovation = { ...innovation, reference: newReference };
    setInnovationData((pre) => {
      return {
        innovationType: {
          ...pre.innovationType,
          [innovationType]: newInnovation,
        },
      };
    });
  };
  return (
    <div className="reference-container">
      <p className="text-ss">Reference (optional) : </p>
      <div className="reference-fields-wrapper">
        <div className="reference-field-wrapper">
          <label>
            <p className="text-ss">
              URL (Enter website URL including http:// or https://):
            </p>
          </label>
          <input
            type="text"
            value={
              innovationData?.innovationType[innovationType]?.reference?.url ||
              ""
            }
            onChange={(e) => handleInnovationUrlRef(e)}
          />
        </div>

        <div className="field-wrapper-line">
          <label>
            <p className="text-ss">Text:</p>
          </label>
          <input
            type="text"
            value={
              innovationData?.innovationType[innovationType]?.reference?.text ||
              ""
            }
            onChange={(e) => handleInnovationTextRef(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default InnovationReferenceSection;
