import React, { useContext, useEffect, useRef, useState } from "react";
import { FaSearchLocation, FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import ClassificationContext from "../../store/ClassificationContext";
import lodash from "lodash";

function TypologyCard({ typology }) {
  const classificationCtx = useContext(ClassificationContext);
  const [expandedText, setExpandedText] = useState(false);
  const [showbutton, setShowbutton] = useState(true);
  const textRef = useRef(null);
  const toggleExpanded = () => {
    setExpandedText((boolean) => !boolean);
  };
  const changeClssificationCtx = (typology) => {
    classificationCtx.setTypologyId(typology._id);
  };
  // console.log("classification in card", classification, index, expandedText);
  useEffect(() => {
    //not showing button when texts are fully visable.
    const truncateTexts = () => {
      const crampedTextHeight = textRef.current?.clientHeight;
      const textHeight = textRef.current?.scrollHeight;
      // console.log("heights",textRef.current, textHeight, crampedTextHeight);
      if (textHeight <= crampedTextHeight) {
        setShowbutton(false);
      } else {
        setShowbutton(true);
      }
    };
    const debouncedCheck = lodash.debounce(truncateTexts, 50);
    window.addEventListener("resize", debouncedCheck);
    textRef.current && truncateTexts();
    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };

  }, [textRef.current]);

  return (
    <div className="card classification-card">
      <div className="card-title-button-wrapper">
        <h4>{typology.typologyName}</h4>
        <Link
          to="/MapByTypology"
          className="btn-secondary card-btn"
          onClick={() => {
            changeClssificationCtx(typology);
          }}
        >
          <FaSearchLocation />
        </Link>
      </div>
      <div className="classificaiton-text-read-more-wrapper">
        <div
          ref={textRef}
          className={`classification-text ${expandedText ? "expanded" : ""}`}
        >
          <p className="classification-description">
            {typology.typologyDescription}
          </p>
        </div>

        {showbutton &&
            (expandedText ? (
              <div
                className="toggle-wrapper"
                onClick={() => {
                  toggleExpanded();
                }}
              >
                <div className="show-less">Show less</div>
                <FaAngleUp className="show-less-toggle" />
              </div>
            ) : (
              <div
                className="toggle-wrapper"
                onClick={() => {
                  toggleExpanded();
                }}
              >
                <div className="read-more">Read more</div>
                <FaAngleDown className="read-more-toggle" />
              </div>
            ))}
      </div>
    </div>
  );
}

export default TypologyCard;
