import { useState, useEffect, useContext } from "react";
import ReactModal from "react-modal";
import GlossaryContext from "../../store/GlossaryContext";
import { Link } from "react-router-dom";



const GlossaryContainer = () => {
  let glossaryContext = useContext(GlossaryContext);

  const [selectedFirstLetter, setSelectedFirstLetter] = useState("A");

  const GlossaryIndex = () => {
    const projects = glossaryContext.allProjects;
    console.log("terms", projects);
    let filtered = projects
      .filter((term) => term.projectName.startsWith(selectedFirstLetter))
      .sort((a, b) => a.projectName.localeCompare(b.projectName));

    return (
      <div className="glossary-index">
        {filtered.length !== 0 ? (
          filtered.map((term, index) => (
            <div key={"term" + index}>
              <Link
                to={`/ProjectPage/${term._id}`}
                className="link-underbar"
                onClick={() => hideGlossary()}
              >
                {term.projectName}
              </Link>
            </div>
          ))
        ) : (
          <p style={{ color: "grey" }}>
            Projects starting with {selectedFirstLetter} don't exist.
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (glossaryContext.showGlossary) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [glossaryContext.showGlossary]);

  const hideGlossary = () => {
    setSelectedFirstLetter(null);
    glossaryContext.setShowGlossary(false);
  };
  const LetterSelector = ({ letter }) => {
    return (
      <div
        className="glossary-letter"
        onClick={() => setSelectedFirstLetter(letter)}
      >
        {letter}
      </div>
    );
  };
  const LetterSelectors = () => {
    return (
      <div className="glossary-letters border-right">
        <LetterSelector letter="A" />
        <LetterSelector letter="B" />
        <LetterSelector letter="C" />
        <LetterSelector letter="D" />
        <LetterSelector letter="E" />
        <LetterSelector letter="F" />
        <LetterSelector letter="G" />
        <LetterSelector letter="H" />
        <LetterSelector letter="I" />
        <LetterSelector letter="J" />
        <LetterSelector letter="K" />
        <LetterSelector letter="L" />
        <LetterSelector letter="M" />
        <LetterSelector letter="N" />
        <LetterSelector letter="O" />
        <LetterSelector letter="P" />
        <LetterSelector letter="Q" />
        <LetterSelector letter="R" />
        <LetterSelector letter="S" />
        <LetterSelector letter="T" />
        <LetterSelector letter="U" />
        <LetterSelector letter="V" />
        <LetterSelector letter="W" />
        <LetterSelector letter="X" />
        <LetterSelector letter="Y" />
        <LetterSelector letter="Z" />
      </div>
    );
  };


  return (
    <ReactModal
      isOpen={glossaryContext.showGlossary}
      ariaHideApp={false}
    >
      <div className="glossary-fullscreen">
        <div className="glossary-header border-bottom">
          <div className="glossary-button-container">
            <div className="glossary-cancel" onClick={() => hideGlossary()}>
              Cancel
            </div>
          </div>
          <h1>Projects</h1>
        </div>
        <div className="glossary-body">
          {LetterSelectors() }
          <div className="glossary-entry-def">{GlossaryIndex()}</div>
        </div>
      </div>
    </ReactModal>
  );
};

export default GlossaryContainer;
