import ClassificationContext from "./ClassificationContext";
import { useState } from "react";
import axios from "axios";
export default function ClassificationProvider({ children }) {
  const [allTypologies, setAllTypologies] = useState(
    JSON.parse(sessionStorage.getItem("AllTypologies")) || []
  );
  const [allInnovations, setAllInnovations] = useState(
    JSON.parse(sessionStorage.getItem("allInnovations")) || []
  );

  const [innovationId, setInnovationId] = useState(
    JSON.parse(sessionStorage.getItem("lastInnovationId")) || ""
  );
  const [projectId, setProjectId] = useState();
    const [lastFieldName, setLastFieldName] = useState(JSON.parse(sessionStorage.getItem("lastFieldName")) || "")
  //
  // set the initial state for classifications
  const firstClassifications = [];
  const baseUrl = process.env.REACT_APP_BASE_URL;
  sessionStorage.setItem(
    "initialClassifications",
    JSON.stringify(firstClassifications)
  );

  function initialClassificationState() {
    const classificationsData = JSON.parse(
      sessionStorage.getItem("lastClassifications")
    );
    const initialClassificationsData = JSON.parse(
      sessionStorage.getItem("initialClassifications")
    );
    if (classificationsData) {
      return classificationsData;
    } else {
      return initialClassificationsData;
    }
  }

  const [typologyId, setTypologyId] = useState(() =>
  JSON.parse(sessionStorage.getItem("lastTypologyId")) || ""
  );
  //
  //
  // set the initial state for projects
  const firstProjects = [];

  sessionStorage.setItem("initialProjects", JSON.stringify(firstProjects));

  function initialProjectState() {
    const projectsData = JSON.parse(sessionStorage.getItem("lastProjects"));
    const initialProjectsData = JSON.parse(
      sessionStorage.getItem("initialProjects")
    );
    if (projectsData) {
      return projectsData;
    } else {
      return initialProjectsData;
    }
  }

  const [projects, setProjects] = useState(() => initialProjectState());
// console.log('proje',projects)
  const getAllTypologies = async () => {
    try {
      let response = await axios.get(baseUrl+"/api/typology");
      if (response.status !== 200) {
        throw new Error("Fetch for classifications failed");
      }
      setAllTypologies(response.data);
      sessionStorage.setItem("allTypologies", JSON.stringify(response.data));
    } catch (err) {
      console.log("Error on Client-Side: Classifications", err);
    }
  };
  const getAllInnovations = async () => {
    try {
      let response = await axios.get(baseUrl+"/api/innovation");
      if (response.status !== 200) {
        throw new Error("Fetch for innovations failed");
      }
      const data = response.data;

      setAllInnovations(data);
      setInnovationId(data[0]._id);
      sessionStorage.setItem("allInnovations", JSON.stringify(data));
    } catch (err) {
      console.log("Error on Client-Side: innovations", err);
    }
  };

  const getProjectsByTypology = async (id) => {
    if (id) {
      try {
        const response = await axios.get(
          baseUrl+`/api/project/findProjectsByTypology/${id}`
        );
        if (response.status !== 200) {
          throw new Error("Fetch for project failed");
        }
        // console.log("projects by typology in context", response.data);
        setProjects(response.data.projects);
        setLastFieldName(response.data.typology.typologyName)
        //for refresh project page
        sessionStorage.setItem("lastProjects", JSON.stringify(response.data.projects));
        sessionStorage.setItem(
          "lastTypologyId",
          JSON.stringify(typologyId)
        );
        sessionStorage.setItem(
          "lastFieldName",
          JSON.stringify(response.data.typology.typologyName))
      } catch (err) {
        console.log("Error on client-side.", err);
      }
    }
  };

  const getProjectsByInnovation = async (id) => {
    if (id) {
      try {
        const response = await axios.get(
          baseUrl+`/api/project/findProjectsByInnovation/${id}`
        );
        if (response.status !== 200) {
          throw new Error("Fetch for project failed");
        }
        setProjects(response.data.projects);
        setLastFieldName(response.data.innovation?.innovationName)
        // console.log('d', response.data)
        setInnovationId(id)
        //
        console.log(
          "getting projects by innovation in context",
          innovationId,
          response.data
        );
        sessionStorage.setItem("lastProjects", JSON.stringify(response.data.projects));
        sessionStorage.setItem(
          "lastInnovationId",
          JSON.stringify(id)
          
        );
        sessionStorage.setItem(
          "lastFieldName",
          JSON.stringify(response.data.innovation.innovationName))
        //
      } catch (err) {
        console.log("Error on client-side.", err);
      }
    }
  };

  return (
    <ClassificationContext.Provider
      value={{
        allInnovations,
        allTypologies,
        innovationId,
        setInnovationId,
        typologyId,
        setTypologyId,
        projects,
        projectId,
        lastFieldName,
        setProjectId,
        getProjectsByTypology,
        getProjectsByInnovation,
        getAllInnovations,
        getAllTypologies,
      }}
    >
      {children}
    </ClassificationContext.Provider>
  );
}
