import React, { useContext, useEffect, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp, FaSearchLocation } from "react-icons/fa";
import { Link } from "react-router-dom";
import ClassificationContext from "../../store/ClassificationContext";
import TextWithGlossaryLinks from "../Glossary/TextWithGlossaryLinks";
import lodash from "lodash";
function ProjectExpandableDescription({ innovation, projectId }) {
  const classificationCtx = useContext(ClassificationContext);
  const [expandedText, setExpandedText] = useState(false);
  const [showbutton, setShowbutton] = useState(true);
  const textRef = useRef(null);
  useEffect(() => {
    //not showing button when texts are fully visable.
    const truncateTexts = () => {
      const crampedTextHeight = textRef.current?.clientHeight;
      const textHeight = textRef.current?.scrollHeight;
      // console.log("heights",textRef.current, textHeight, crampedTextHeight);
      if (textHeight <= crampedTextHeight) {
        setShowbutton(false);
      } else {
        setShowbutton(true);
      }
    };
    const debouncedCheck = lodash.debounce(truncateTexts, 50);
    window.addEventListener("resize", debouncedCheck);
    textRef.current && truncateTexts();
    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [textRef.current]);
  //Change Innovation in context
  const changeInnovationCtx = () => {
    // console.log("Innovation", Innovation);

    classificationCtx.setInnovationId(innovation.Id._id);
    //save project id to find it in the map when map page is navigated from project page.
    classificationCtx.setProjectId(projectId);
  };
  const toggleExpanded = () => {
    setExpandedText((boolean) => !boolean);
  };
  return (
    <div className="project-classification-wrapper p-wrapper with-title ">
      <Link
        to="/MapByInnovation"
        className="btn-secondary project-btn-contents-wrapper"
        onClick={() => {
          changeInnovationCtx();
        }}
      >
        <FaSearchLocation />
        <h6 className={"project-btn-text"}>{innovation.Id.innovationName}</h6>
      </Link>

      {innovation.innovationDescription && (
        <div className="classificaiton-text-read-more-wrapper">
          <div
            ref={textRef}
            className={`classification-text ${expandedText ? "expanded" : ""}`}
          >
            <TextWithGlossaryLinks text={innovation.innovationDescription} />
            <div className="reference-container reference-padding">
              <p className="text-ss">Reference:</p>
              <div className="reference-fields-wrapper">
                {innovation?.reference?.text && (
                  <p className="text-ss">{innovation?.reference?.text}</p>
                )}
                {innovation?.reference?.url && (
                  <a
                    className="text-ss link-underbar"
                    href={innovation?.reference?.url}
                  >
                    Reference link
                  </a>
                )}
              </div>
            </div>
          </div>

          {showbutton &&
            (expandedText ? (
              <div
                className="toggle-wrapper"
                onClick={() => {
                  toggleExpanded();
                }}
              >
                <div className="show-less">Show less</div>
                <FaAngleUp className="show-less-toggle" />
              </div>
            ) : (
              <div
                className="toggle-wrapper"
                onClick={() => {
                  toggleExpanded();
                }}
              >
                <div className="read-more">Read more</div>
                <FaAngleDown className="read-more-toggle" />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default ProjectExpandableDescription;
