import React from "react";
import awsconfig from "../aws-exports-form";
import { Amplify } from "aws-amplify";
import { Authenticator, useTheme, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { ProjectForm } from "./form";

Amplify.configure(awsconfig);

function FormLogin() {
  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <View textAlign="center" padding={tokens.space.large}>
            <h5>Form Login</h5>
          </View>
        );
      },
    },
  };
  return (
    <div className="form-login">
      <Authenticator components={components}>
        {({ signOut, user }) => (
          <div>
            <ProjectForm signOut={signOut} user={user} />
          </div>
        )}
      </Authenticator>
    </div>
  );
}

export default FormLogin;
