import React from "react";
import mapboxgl from "mapbox-gl";
import { Link } from "react-router-dom";
// import axios from "axios";
// import { Link } from "react-router-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function ProjectMap({ location }) {
  const projectLocation = location;
  // const mapContainer = useRef();
  const projectCoordinates = projectLocation.coordinates;
  // const [map, setMap] = useState(null);

  const staticMap = `https://api.mapbox.com/styles/v1/sapl123/clfju3odb000t01o7rodqxvju/static/pin-l+f44(${projectCoordinates[0]},${projectCoordinates[1]})/${projectCoordinates[0]},${projectCoordinates[1]},11,0/300x200?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

  // useEffect(() => {
  //   const getMap = () => {
  //     const newMap = new mapboxgl.Map({
  //       container: mapContainer.current,
  //       style: process.env.REACT_APP_MAPBOX_STYLE_URL,
  //       center: projectCoordinates,
  //       zoom: 10,
  //       attributionControl: false,
  //     });
  //     setMap(newMap);
  //   };
  //   projectLocation && getMap();
  // }, [projectLocation, projectCoordinates]);

  // useEffect(() => {
  //   const getMarker = () => {
  //     new mapboxgl.Marker({
  //       color: "#FF0000",
  //     })
  //       .setLngLat(projectCoordinates)
  //       .addTo(map);
  //   };
  //   map && getMarker();
  // }, [map, projectCoordinates]);d
  return (
    <>
      {/* <div className="map sm" ref={mapContainer} /> */}
      <div className="map sm">
        <Link to="/MapByInnovation">
        <img src={staticMap} alt="staticMap" className="map sm" /></Link>
      </div>
      <div className="map-address">
        <p> {location.address}</p>
      </div>
    </>
  );
}

export default ProjectMap;
