import axios from "axios";

export const fetchTypologies = async () => {
  try {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const response = await axios.get(baseUrl+"/api/typology");
    const typologies = response.data;
    console.log("data from typology", typologies);
    return typologies;
  } catch (error) {
    console.error("Error fetching typologies:", error);
    return [];
  }
};

// Updated handleTypologySelect function in TypologyUtils.js
export const handleTypologySelect = (
  event,
  selectedTypology,
  selectedTypologies,
  setSelectedTypologies
) => {
  const selectedTypologyId = selectedTypology._id;

  if (event.target.checked) {
    if (selectedTypologies.length < 3) {
      setSelectedTypologies([...selectedTypologies, {Id:selectedTypologyId}]);
    } else {
      event.target.checked = false;
      alert("You can only select up to 3 typologies.");
    }
  } else {
    setSelectedTypologies(
      selectedTypologies.filter(
        (typology) => typology.Id!== selectedTypologyId
      )
    );
  }
};
