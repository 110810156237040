import GlossaryContext from "./GlossaryContext";
import { useState, useEffect } from "react";

export default function GlossaryProvider({ children }) {
  const [allProjects, setAllProjects] = useState([]);

  const [showGlossary, setShowGlossary] = useState(false);

  useEffect(() => {
    const getAllDefinitions = async () => {
      try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        let response = await fetch(baseUrl + "/api/project/findAllProjects");
        if (response.status !== 200) {
          throw new Error("Fetch for glossary words failed");
        }
        let data = await response.json();
        setAllProjects(data);
        // setAllDefinitions(definitions.map(fixDefinition));
      } catch (err) {
        console.log("Error on Client-Side: Glossary Defintions", err);
      }
    };

    getAllDefinitions();
  }, []);

  return (
    <GlossaryContext.Provider
      value={{
        allProjects,

        showGlossary,
        setShowGlossary,
      }}
    >
      {children}
    </GlossaryContext.Provider>
  );
}
