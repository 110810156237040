import UCLogo from "../images/Logos/UC-vert-rgb.svg";
import SAPLWordMark from "../images/Logos/SAPL-wordmark.svg";
import { Link } from "react-router-dom";

export default function HeaderWithLogo() {
  return (
    <section className="container-top">
      <div className="logos-container top">
        <div className="logos-wrapper">
          <div className="logo-wrapper">
            <img className="logo" src={UCLogo} alt="UC logo" />
          </div>
          <div className="logo-wrapper name-wrapper">
            <Link to="/" className="logo-name">
            Design That Changes Calgary
            </Link>
          </div>
        </div>
        <div className="logo-wrapper">
          <img className="logo" src={SAPLWordMark} alt="SAPL wordmark" />
        </div>
      </div>
    </section>
  );
}
