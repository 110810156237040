import { Auth } from "aws-amplify";
import axios from "axios";

export const handleSubmit = async (event, projectData,primaryImg, otherImgs, onSuccess, onError) => {
  event.preventDefault();
  const formData = new FormData();
 
  // Append the primary image
  if (primaryImg) {
    formData.append(
      "primaryImage",
      primaryImg
    );
  }
// console.log('d', projectData.images, projectData.images)
  // Append additional images
  if (otherImgs && otherImgs.length > 0) {
    otherImgs.forEach((image, index) => {
      formData.append("imageArray", image);
    });
  }

  // delete projectData.primaryImage;
  // delete projectData.images;
  formData.append("data", JSON.stringify(projectData));

  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const session = await Auth.currentSession();
    const authToken = session.getIdToken().getJwtToken();
    // const presignedData = await axios.post("/api/s3/presigned", {
    //   contentTypes: [...projectData.images].map((file) => file.type),
    // });
    const response = await axios.post(
      baseUrl + `/api/adminProject/create`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
        timeout: 60000, // 60 seconds
      }
    );
    console.log("Project created successfully:", response.data);
    onSuccess(response.data);
   
  } catch (error) {
    console.error("Error creating new project:", error);
    onError(error);
  }
};
