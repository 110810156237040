import { createContext } from "react";

const ClassificationContext = createContext({
  // All available classification objects
  allTypologies: [],
  // All available gateway words
  allInnovations: [],
  // Initialize gateway word
  innovationId: undefined,
  // Initialize classification
  typologyId: undefined,
  // Initialize project summaries
  lastFieldName:undefined,
  projects: [],
  projectId:"",
  getProjectsByInnovation: ()=>null,
  getProjectsByTypology: ()=>null,
  getAllInnovations :()=>null,
  getAllTypologies :()=>null
});

export default ClassificationContext;
