import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-slideshow-image/dist/styles.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "swiper/swiper.min.css";
// import "swiper/modules/pagination/pagination.min.css";

// import "swiper/modules/navigation/navigation.min.css";

import { Pagination, Navigation, Mousewheel } from "swiper";

const ProjectGallery = ({
  primaryImg,
  primaryImgByUrl,
  otherImg,
  otherImgByUrl,
  primaryImgFileCaption,
  otherImgFilesCaptions,
}) => {
  console.log(
    "pre",
    primaryImg,
    otherImg,
    primaryImgByUrl,
    otherImgByUrl,
    primaryImgFileCaption,
    otherImgFilesCaptions
  );

  return (
    <Swiper
      pagination={{ dynamicBullets: true }}
      loop={true}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
        clickable: true,
      }}
      mousewheel={{ invert: true, sensitivity: 2, releaseOnEdges: true }}
      modules={[Pagination, Navigation, Mousewheel]}
      className="project-swiper"
    >
      {/* <figure> */}
      {primaryImgByUrl && (
        <figure>
          <SwiperSlide key="primaryWithUrl">
            <img src={primaryImgByUrl.url} alt="projectimage" />
            <div className="captions-wrapper">
              <figcaption className="caption-text">
              {primaryImgByUrl?.imageSource && (
                  <a
                    className="link-underbar"
                    href={`${primaryImgByUrl.imageSource}`}
                  >
                    Image source
                  </a>
              )}
              {primaryImgByUrl.photoCredit && (
                <div>
                  Photo credit: <span>{primaryImgByUrl.photoCredit}</span>
                </div>
              )}
                </figcaption>
              
            </div>
          </SwiperSlide>
        </figure>
      )}
      {primaryImg && (
        <SwiperSlide key="primaryWithFile">
          <img
            src={primaryImg.primaryOriginalUrl}
            srcSet={`${primaryImg.primaryResizedGalleryUrl} 430w, ${primaryImg.primaryOriginalUrl}`}
            alt="projectimageWithFile"
          />
          <div className="captions-wrapper">
            {primaryImgFileCaption?.photoCredit && (
              <figcaption className="caption-text">
                Photo credit: <span>{primaryImgFileCaption.photoCredit}</span>
              </figcaption>
            )}
          </div>
        </SwiperSlide>
      )}
     

      {otherImg.map((img, index) => {
        return (
          <SwiperSlide key={`slide-${index}`}>
            <img
              // key={`img-${index}`}
              src={img.otherOriginalUrl}
              srcSet={`${img.otherResizedGalleryUrl} 430w, 
                    ${img.otherOriginalUrl}`}
              alt="projectimage"
            />
            {otherImgFilesCaptions[index]?.photoCredit && (
                <figcaption className="caption-text">
                  Photo credit: <span>{otherImgFilesCaptions[index]?.photoCredit}</span>
                </figcaption>
              )}
              
          </SwiperSlide>
        );
      })}
      {otherImgByUrl.map((img, index) => {
        return (
        
            <SwiperSlide key={`slideByUrl-${index}`}>
              <img  src={img.url} alt="projectimage" />
              <figcaption className="caption-text">
              {img.imageSource && (
                  <a className="link-underbar" href={`${img.imageSource}`}>
                    Image source
                  </a>
              )}
              {img.photoCredit && (
                <div>
                  Photo credit: <span>{img?.photoCredit}</span>
                </div>
              )}
              </figcaption>
              {/* {img.photoCredit && (
                <figcaption className="caption-text">
                  Photo credit: <span>{img?.photoCredit}</span>
                </figcaption>
              )} */}
              
            </SwiperSlide>
            
         
        );
      })}

      <div className="slider-Controller">
        <div className="swiper-button-prev slider-arrow"></div>
        <div className="swiper-button-next slider-arrow"></div>
      </div>
    </Swiper>
  );
};

export default ProjectGallery;
