import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import GlossaryContainer from "../components/Glossary/GlossaryContainer";
import ProjectGallery from "../components/ProjectPage/ProjectGallery";
import ProjectSlider from "../components/HorizontalProjectSummaries/ProjectSlider";
import ClassificationContext from "../store/ClassificationContext";
import TextWithGlossaryLinks from "../components/Glossary/TextWithGlossaryLinks";
import ProjectMap from "../components/ProjectPage/ProjectMap";
import ProjectTitle from "../components/ProjectPage/ProjectTitle";
import ProjectCollaboratorsOwner from "../components/ProjectPage/ProjectCollaboratorsOwner";
import ProjectRelatedTypologies from "../components/ProjectPage/ProjectRelatedTypologies";
import ProjectRelatedInnovations from "../components/ProjectPage/ProjectRelatedInnovations";

const ProjectPage = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const params = useParams();
  const chosenID = params.id;
  const classificationCtx = useContext(ClassificationContext);
  const [project, setProject] = useState();
  // const gateWord = classificationCtx.gatewayWord || "";
  let [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const sliderRelatedProjects = classificationCtx.projects.filter(function (
    obj
  ) {
    return obj._id !== project?._id;
  });
  // console.log("project page",classificationCtx.lastFieldName);
  useEffect(() => {
    const getProject = async () => {
      try {
        let response = await fetch(baseUrl + "/api/project/" + chosenID);
        if (response.status !== 200) {
          throw new Error("Fetch for project ID failed");
        }
        let data = await response.json();
        console.log("project page: project", data);
        setProject(data);
        classificationCtx.setProjectId(data._id);
      } catch (err) {
        console.log("Error on client-side.", err);
      }
    };
    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenID]);
  useEffect(() => {
    const isRelatedProjects = async () => {
      const lastFieldKeyword = classificationCtx.lastFieldName;
      const projectInnovations = project.innovationType;
      const primaryInnovation =
        projectInnovations?.primaryInnovation?.Id?.innovationName;
      const secondaryInnovation =
        projectInnovations?.secondaryInnovation?.Id?.innovationName;
      const tertiaryInnovation =
        projectInnovations?.tertiaryInnovation?.Id?.innovationName;
      const typologiesNameArr = project.typologies.map(
        (typology) => typology?.Id?.typologyName
      );
      const keywordListArr = [
        primaryInnovation,
        secondaryInnovation,
        tertiaryInnovation,
        ...typologiesNameArr,
      ];
      console.log(
        "asgt",
        keywordListArr,
        projectInnovations?.primaryInnovation?.Id
      );
      const hasKeyword = keywordListArr.includes(lastFieldKeyword);
      const isTypology = typologiesNameArr.includes(lastFieldKeyword);
      console.log("boolean", hasKeyword);
      const innovationId = projectInnovations?.primaryInnovation?.Id?._id;
      if (!hasKeyword) {
        // console.log("innovation to find project", innovationId);
        await classificationCtx.getProjectsByInnovation(innovationId);
      }
      if (isTypology) {
        classificationCtx.setInnovationId(innovationId);
      }
    };
    project && isRelatedProjects();
  }, [project]);
  // console.log("project id", gateWord);
  if (project !== undefined) {
    return (
      <div className="container">
        <main className="content-container project-container">
          <section className="project-main">
            <ProjectGallery
              primaryImg={project.primaryImage}
              primaryImgByUrl={project.primaryImageByUrl}
              otherImg={project.images}
              otherImgByUrl={project.imagesByUrl}
              primaryImgFileCaption={project.primaryImgFileCaption}
              otherImgFilesCaptions={project.otherImgFilesCaptions}
            />
            <ProjectTitle
              projectName={project.projectName}
              yearOfCompletion={project.completionYear}
              designer={project.primaryConsultant}
            />
          </section>

          <div className="project-description-map-wrapper">
            {project.projectDescription && (
              <section className="project-description text-container">
                <TextWithGlossaryLinks
                  text={project.projectDescription}
                  keyProp={project._id}
                />
                {(project?.projectReference?.text ||
                  project?.projectReference?.url) && (
                  <div className="reference-container ">
                    <p className="text-ss">Reference:</p>
                    <div className="reference-fields-wrapper">
                      {project?.projectReference?.text && (
                        <p className="text-ss">
                          {project?.projectReference?.text}
                        </p>
                      )}
                      {project?.projectReference?.url && (
                        <a
                          className="text-ss link-underbar"
                          href={project?.projectReference?.url}
                        >
                          Reference link
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </section>
            )}
            {project.location && (
              <section className="project-map text-container">
                <ProjectMap location={project.location} />
              </section>
            )}
          </div>
          <section className="text-container with-title">
            <ProjectCollaboratorsOwner
              collaborators={project.otherContributors}
              owner={project.owner}
            />
          </section>
          {project.typologies.length !== 0 && (
            <ProjectRelatedTypologies
              projectTypologies={project.typologies}
              projectId={project._id}
            />
            // </section>
          )}

          {project.innovationType && (
            <ProjectRelatedInnovations
              projectInnovation={project.innovationType}
              projectId={project._id}
            />
            // </section>
          )}
          {project.relatedResources.length !== 0 && (
            <section className="text-container with-title">
              <h4>OTHER RESOURCES</h4>
              <ul>
                {project.relatedResources.map((resource, i) => (
                  <li key={`otherResources-${i}`}>
                    <div className="otherresource-wrapper">
                      <span>{resource.title} : </span>
                      <span>
                        <a
                          className="other-resources-link link-underbar"
                          href={
                            resource.link &&
                            (resource.link.includes("https") ||
                            resource.link.includes("http")
                              ? resource.link
                              : `https://${resource?.link}`)
                          }
                        >
                          {resource.link}
                        </a>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </section>
          )}
          {sliderRelatedProjects.length !== 0 && (
            <section className="text-container with-title">
              <h4>
                OTHER{" "}
                <span>{classificationCtx.lastFieldName.toUpperCase()}</span>{" "}
                PROJECTS
              </h4>
              <ProjectSlider
                slides={sliderRelatedProjects}
                selectedProjectIndex={selectedProjectIndex}
                setSelectedProjectIndex={setSelectedProjectIndex}
              />
            </section>
          )}
        </main>
        <Navbar />
        <GlossaryContainer />
      </div>
    );
  }
  return null;
};

export default ProjectPage;
