import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaSearchLocation } from "react-icons/fa";
import ClassificationContext from "../../store/ClassificationContext";

function ProjectRelatedTypologies({ projectTypologies, projectId }) {
  const typologyArr = projectTypologies;
  const classificationCtx = useContext(ClassificationContext);

  const changeGatewayWordId = (typologyId) => {
    classificationCtx.setTypologyId(typologyId);
    // console.log('typology changed on cick',typologyId)
    classificationCtx.setProjectId(projectId);
  };
 
  const filteredTypologies = typologyArr.some(
    (typology) => typology.Id !== null
  );

  // console.log("typologyArry", typologyArr, filteredTypologies);
  return (
    <>
      {typologyArr && filteredTypologies && (
         <section className="project-related-category text-container with-title">
          <h4>RELATED TYPOLOGIES</h4>
          <div className="project-buttons-wrapper">
            {typologyArr &&
              typologyArr?.map((gateway, i) => {
                if (gateway.Id)
                  return (
                    <button className="btn-secondary" key={`gateway-${i}`}>
                      <Link
                        key={`gateway-link-${i}`}
                        to="/MapByTypology"
                        className="project-btn-contents-wrapper"
                        onClick={() => {
                          changeGatewayWordId(gateway.Id._id);
                        }}
                      >
                        <FaSearchLocation key={`gateway-icon-${i}`} />
                        <h6
                          className={"project-btn-text"}
                          key={`gateway-category-${i}`}
                        >
                          {gateway.Id?.typologyName}
                        </h6>
                      </Link>
                    </button>
                  );
              })}
          </div>
        </section>
      )}
    </>
  );
}

export default ProjectRelatedTypologies;
